import { Brand } from "../core/Brand";
import { Currency } from "./Currency";
import { OMath } from "./OMath";
import slugify from "slugify";

export class DataLayer {
  static init() {
    window.dataLayer = window.dataLayer || [];
  }

  static push(data) {
    if (window) {
      DataLayer.init();

      const payload = data;

      if (payload?.ecommerce !== null) {
        if (Brand.name) payload.brand = slugify(Brand.name).toLowerCase();
      }

      window.dataLayer.push(payload);
    }
  }

  static getOrderItems(items) {
    if (!items || items.length === 0) return null;

    const getVariants = (item) => {
      let variants = [];

      item.items?.map((a) => {
        a.items?.filter((b) => b.selected && variants.push(b.name));
      });

      return variants.join(" | ");
    };

    const brandName = Brand.name;

    return items.map((item) => {
      return {
        item_id: item.id,
        item_name: item.name,
        quantity: item.quantity,
        item_brand: brandName,
        item_variant: getVariants(item),
        price: this.calculateItemPrice(item),
      };
    });
  }

  static calculateItemPrice(item) {
    const props = ["items", "sizes", "extra_ingredients", "doughs", "edges"];

    let total =
      Math.round((item.price || 0) * 100) *
      (item.selected !== false && item.quantity > 0 ? 1 : 0);
    for (const key of props) {
      if (item[key] && item[key].length > 0) {
        for (const subItem of item[key]) {
          total += this.calculateItemPrice(subItem) * 100;
        }
      }
    }

    return +OMath.div(OMath.times(total, item.quantity), 100).toFixed(2);
  }

  static mountPrice(item) {
    if (item.price > 0) {
      return Currency.formatCurrency(Number(item.price));
    }

    if (item.price >= 0 && item.sizes.length === 0) {
      return Currency.formatCurrency(Number(item.price));
    }

    if (item.sizes.length > 0) {
      const minValue = (item.sizes || []).reduce((prev, current) =>
        prev.price < current.price ? prev : current
      );

      if (minValue.price === 0) {
        if (item.items.length > 0) {
          let totalPrice = 0;
          (item.items || []).forEach((subitem) => {
            if (subitem.min > 0) {
              const minValue = (subitem.items || []).reduce((prev, current) =>
                prev.price < current.price ? prev : current
              );
              totalPrice = totalPrice + minValue.price;
            }
          });

          return totalPrice;
        }
      }

      return minValue.price;
    }
  }
}
