import { Address } from "@olga-food/schemas/lib/classes/schemas/address/Address";
import {
	Delivery,
	DeliveryType,
} from "@olga-food/schemas/lib/classes/schemas/order/delivery/Delivery";
import { CatalogAPI } from "api/CatalogAPI";
import { EnvironmentAPI } from "api/EnvironmentAPI";
import { OrderAPI } from "api/OrderAPI";
import { BaseStorage } from "storage/BaseStorage";
import { OrderStorage } from "storage/OrderStorage";
import { Address as AddressCore } from "./Address";
import { Brand } from "./Brand";
import { Catalog } from "./Catalog";
import { Table } from "./Table";

const store_key = "of-store";

export const StoreEvents = {
	STORE_CHANGED: "STORE_CHANGED",
};

export class Store {

	constructor ({ id, alias, shippinghub }) {
		this.id = id;
		this.alias = alias;
		this.shippinghub = shippinghub;
	}

	static async fromAlias (alias) {
		const baseStorage = new BaseStorage({ key: alias });
		let configs = baseStorage.getData();
		if (!configs) {
			const unitConfigurations = await EnvironmentAPI.getS3Configurations(alias);
			baseStorage.setData(unitConfigurations);
			configs = baseStorage.getData();
		}
		
		return new Store({
			id: configs.id,
			alias: alias,
			shippinghub: configs?.shippinghub
		});
	}

	static async install(alias) {
		const unitConfigurations = await EnvironmentAPI.getS3Configurations(alias);
		const baseStorage = new BaseStorage({ key: alias });
		baseStorage.setData(unitConfigurations);
	}

	static async setStore(storeAlias) {
		Store.alias = storeAlias;
	}

	static set alias(alias) {
		if (!alias) {
			localStorage.removeItem(store_key);
		} else {
			localStorage.setItem(store_key, alias);
		}

		window.dispatchEvent(
			new CustomEvent(StoreEvents.STORE_CHANGED, { detail: alias })
		);
		return alias;
	}

	static get alias() {
		return localStorage.getItem(store_key);
	}

	static getStore() {
		try {
			if (Store.alias) {
				const store = localStorage.getItem(Store.alias);
				return store ? JSON.parse(store) : null;
			}
			return null;
		} catch (err) {
			return null;
		}
	}

	static get id() {
		const store = Store.getStore();
		return store?.id;
	}

	static get name() {
		const store = Store.getStore();

		if (!store) return null

		return store.name;
	}

	static get avatar() {
		const store = Store.getStore();
		let avatar = store?.avatar || "";

		if (!store) {
			return Brand.avatar;
		}

		if (avatar.startsWith("http")) {
			return avatar;
		} else if (!avatar) {
			return null;
		}

		return `${process.env.NEXT_PUBLIC_CDN_ASSETS}/${avatar}`;
	}

	static async catalog() {
		const storeAlias = Store.alias;

		if (!storeAlias) {
			const catalog = await CatalogAPI.getS3Catalog(Brand.alias, 'DELIVERY');
			Catalog.memorizeCatalog(Brand.alias, catalog);
			return catalog;
		} else {
			let catalog = null;

			if (Table.id) {
				catalog = await CatalogAPI.getS3Catalog(storeAlias, "INDOOR");
			} else {
				const catalogService = Store.getCatalogService();
				if (!catalogService) {
					return {
						type: "catalog", 
						items: [] 
					};
				}

				catalog = await CatalogAPI.getS3Catalog(storeAlias, catalogService);
			}

			Catalog.memorizeCatalog(storeAlias, catalog);
			return catalog;
		}
	}

	static getCatalogService (delivery = null) {
		const catalogPlace = Store.getCatalogPlace(delivery);
		const catalogService = Store.getCatalogServiceByPlace(catalogPlace);
		return catalogService;
	}

	static getCatalogPlace (delivery = null) {
		if (!delivery) {
			delivery = OrderStorage.getDelivery();
		}

		const catalogPlaces = {
			[DeliveryType.DELIVER_ON_ADDRESS]: 'DELIVERY',
			[DeliveryType.GET_ON_PLACE]: 'TAKEOUT',
			[DeliveryType.INDOOR]: 'INDOOR',
		};
		
		return catalogPlaces[delivery?.type] || 'DELIVERY';
	}

	static async setStoreByAddress({ delivery, unit } = {}) {
		const isLocal = [DeliveryType.GET_ON_PLACE, DeliveryType.INDOOR].includes(delivery?.type);
		
		if (isLocal) {
			OrderStorage.setDelivery(delivery);
			Store.alias = unit?.alias;
			return true;
		} else {
			const address = AddressCore.current;
			const emptyDelivery = Delivery.fromObject({
				address: null,
				distance: null,
				type: DeliveryType.DELIVER_ON_ADDRESS,
				total: null,
				deliveryStrip: null,
			});

			if (!address) {
				OrderStorage.setDelivery(emptyDelivery);
				Store.alias = null;
				return false;
			}

			//Buscar a loja mais próxima do endereço
			const result = await Brand.getNearByUnitsFromAddress(address.geolocation);
			if (!result) {
				OrderStorage.setDelivery(emptyDelivery);
				Store.alias = null;
				return false;
			}

			const { unit: store, delivery } = result || {};

			if (address && store.alias) {
				const storeInstance = await Store.fromAlias(store.alias);
				const shippingConfigs = storeInstance.getShippingConfigs();

				if (shippingConfigs?.gatewayType && shippingConfigs?.allowPreview) {
					const previewResponse = await OrderAPI.getDeliveryPreview(store.id, address);
				
					if (previewResponse.getErrors([]).length > 0) {
						throw new Error(previewResponse.getErrors()[0]);
					}
	
					const preview = previewResponse.getData({});
	
					delivery.total = preview.deliveryTotal;
					delivery.deliveryStrip.price = preview.deliveryTotal;
				}
			}
			delivery.address = Address.fromObject(address);
			OrderStorage.setDelivery(delivery);
			Store.alias = store?.alias || null;
		}

		return true;
	}

	static get working_time() {
		const store = Store.getStore();
		return store?.working_time;
	}

	static get helpdesk_contact() {
		const store = Store.getStore();
		return store?.helpdesk_contact || Brand.helpdesk_contact;
	}

	static get type() {
		const store = Store.getStore();
		return store?.type || null;
	}

	static get socials() {
		const store = Store.getStore();
		return store?.socials || Brand.socials;
	}

	static get payment_methods() {
		const store = Store.getStore();
		return store?.payment_methods || Brand.payment_methods;
	}

	static get theme() {
		const store = Store.getStore();
		return store?.front_theme_super_app;
	}

	static get keys() {
        const brand = Store.getStore();
        return brand?.keys || null;
    }

	static get description() {
		const store = Store.getStore();
		return store?.metadata?.description || Brand.description;
	}

	static get delivery_time() {
		const store = Store.getStore();
		return store?.delivery_time || {};
	}

	static get address() {
		const store = Store.getStore();
		return store?.address || {};
	}

	static get active() {
		const store = Store.getStore();
		return store?.active || false;
	}

	static get tables() {
		const store = Store.getStore();
		return store?.tables || {};
	}

	static get catalog_places () {
		const store = Store.getStore();
		return store?.catalog_places || null;
	}

	static get external_credentials () {
		const store = Store.getStore();
		return store?.external_credentials || null;
	}

	static get banners() {
		const store = Store.getStore();
		return store?.banners || Brand.banners;
	}

	static getCatalogServiceByPlace (place) {
		const placesByService = Store.catalog_places;

		if (Object.keys(placesByService || {}).length === 0) {
			return 'DELIVERY';
		}

		const catalogService = Object
			.entries(placesByService)
			.find(([catalogService, places]) => places.includes(place));

		if (!catalogService) {
			return 'DELIVERY';
		}

		return catalogService[0];
	}

	static getSwitchs(){
		const store = Store.getStore();
		return store?.switches || {};
	}

	static getPaymentConfigs() {
		const store = Store.getStore();
		return store ? store.paymenthub : null;
	}

	static getDeviceBanners(device = "desktop") {
		const store_banners = Store.banners;
	
		if (!store_banners?.delivery?.[device]?.length === 0) {
			const theme = Store.theme;
			return theme?.covers?.[device] || [];
		  }
	
		const banners = (store_banners?.delivery?.[device] || []).filter((b) => {
		  return b.visibility === null || isCurrentInRange(b.visibility);
		});
		return banners || [];
	}

	getShippingConfigs () {
		return this?.shippinghub || null;
	}
}

function isCurrentInRange(schedule_list) {
	const today = new Date();
  
	const current_day = today.getDay();
	const current_time = today.toTimeString().slice(0, 5).replace(":", ""); // "HH:MM"
  
	function isTimeInRange(current_time, range) {
	  const { start, end } = range;
	  return current_time >= start && current_time <= end;
	}
  
	return schedule_list.some(({ weekDay, times }) => {
	  if (weekDay === current_day) {
		return times.some((range) => isTimeInRange(current_time, range));
	  }
	  return false;
	});
}