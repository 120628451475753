import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import styles from "@/styles/containers/command.module.scss";

import { CheckCircle2, Money, TableRegular } from "@/shared/components/Icons";

import { Button, Drawer, Loading } from "@/shared/components";
import { Brand } from "@/shared/core/Brand";
import { Store } from "@/shared/core/Store";
import { Table as TableCore } from "@/shared/core/Table";

import { OpenTableAPI } from "@/api/OpenTableAPI";

import NetLogicCart from "../Cart/NetLogicCart";
import { PreCloseTable } from "./PreCloseTable";

const FinishOptions = {
  WAITER: "WAITER",
  DESK: "DESK",
  APP: "APP",
};

export default function Table({ onClose }) {
  const [openConfirmClose, setOpenConfirmClose] = useState(false);
  const [openCloseTable, setOpenCloseTable] = useState(false);
  const [closeDesk, setCloseDesk] = useState(false);
  const [closeWaiter, setCloseWaiter] = useState(false);
  const [table, setTable] = useState(null);
  const [loading, setLoading] = useState(true);
  const [polling, setPolling] = useState(false);
  const [selectedEnvironment, setSelectedEnvironment] = useState(null);
  const [selectedFinishOption, setSelectedFinishOption] = useState(
    FinishOptions.DESK
  );
  const [openDeskSuccess, setOpenDeskSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [preClosed, setPreClosed] = useState(false);

  const router = useRouter();

  useEffect(() => {
    setSelectedEnvironment(Brand.alias ? Brand.alias : null);
  }, []);

  const hasServiceAmount =
    table?.totals?.serviceChargeTotal && +table?.totals?.serviceChargeTotal > 0
      ? true
      : false;

  const environmentId = Store.id;
  const tableId = TableCore.id;

  const loadTable = async () => {
    const tableDetailsReq = await OpenTableAPI.getTableDetails(
      environmentId,
      tableId
    );
    const tableDetailsData = tableDetailsReq.getData({});

    if (tableDetailsData?.details?.menuItems) {
      setTable(tableDetailsData?.details);
    }

    setPreClosed(!!tableDetailsData?.preClosed);
  };

  const finishTable = async () => {
    const response = await OpenTableAPI.preFinishTable(environmentId, tableId);

    if (response.getErrors([]).length > 0) {
      return setError({
        title: "Não foi possível realizar o pré-fechamento!",
        description: response.getErrors([])[0],
      });
    }

    setPreClosed(true);
    setOpenConfirmClose(false);
  };

  const onLoad = async () => {
    setLoading(true);
    await loadTable();
    setLoading(false);
    setPolling(true);
  };

  const goToCatalog = () => {
    const newPath = selectedEnvironment
      ? `/${selectedEnvironment}/cardapio`
      : "/cardapio";
    router.push(newPath);
  };

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    if (polling) {
      const timer = setInterval(async () => {
        await loadTable();
      }, 30000);
      return () => clearInterval(timer);
    }
  }, [polling]);

  let menuMobileHeight;
  let heightScrollContainer;

  if (window !== undefined) {
    const menuMobile = document.getElementById("menuMobile");

    if (menuMobile) {
      menuMobileHeight = menuMobile.offsetHeight + 10;
      heightScrollContainer = menuMobileHeight + 75;
    }
  }

  return (
    <div
      className={styles.tableScrollContainer}
      style={{ height: `calc(100dvh - ${heightScrollContainer}px)` }}
    >
      {loading && (
        <div className={styles.loading}>
          <Loading fullScreen />
        </div>
      )}

      {!loading && !table?.menuItems?.length && (
        <div className={styles.empty}>
          <div className={styles.icon}>
            <TableRegular />
          </div>

          <p>Não há pedidos na sua mesa.</p>
          <span>
            O que acha de ver o nosso cardápio e experimentar uma das nossas
            opções?
          </span>

          <Button design="primary" onClick={() => goToCatalog()}>
            Ver cardápio
          </Button>
        </div>
      )}

      {!loading && table?.menuItems?.length > 0 && (
        <>
          {closeDesk && (
            <div className={styles.statusContainer}>
              <div className="d-flex flex-col flex-1">
                <p className={styles.title}>Garçom a caminho</p>
                <span className={styles.subtitle}>
                  Sua conta já foi fechada e o garçom está a caminho para
                  finalizar o pagamento.
                </span>
              </div>
              <Money />
            </div>
          )}

          {/* {(closeDesk || closeWaiter) && (
            <div className={styles.totalsContainer}>
              <ul>
                <li>
                  <p>Valor total inicial</p>
                  <span>
                    {Currency.formatCurrency(table.totals.subtotal)}
                  </span>
                </li>

                {hasServiceAmount === true && (
                  <li>
                    <p>
                      Valor serviço{" "}
                      {table.servico_obrigatorio != "S" ? "(Opcional)" : ""}{" "}
                    </p>
                    <span>
                      {Currency.formatCurrency(+table.totals.serviceChargeTotal)}
                    </span>
                  </li>
                )}

                <li>
                  <p className={styles.benefit}>Benefício aplicado</p>
                  <span className={styles.benefit}>
                    {Currency.formatCurrency(133)}
                  </span>
                </li>
                <li>
                  <p>Total</p>
                  <span className={styles.total}>
                    {Currency.formatCurrency(table.totals.totalDue)}
                  </span>
                </li>
              </ul>

              <div className={styles.gift}>
                  <div className={styles.icon}>
                    <Gift />
                  </div>
                  <p>
                    Cupom de <strong>{Currency.formatCurrency(15)}</strong>{" "}
                    aplicado
                  </p>
                </div>
            </div>
          )} */}

          <NetLogicCart
            table={table}
            onClose={() => onClose && onClose()}
            hideAddMoreItems={closeDesk || closeWaiter}
          />

          {!preClosed && (
            <div
              className={styles.footer}
              style={{ bottom: `${menuMobileHeight}px` }}
            >
              <Button
                design="primary"
                disabled={loading}
                onClick={() => setOpenConfirmClose(true)}
              >
                Pedir conta
              </Button>
            </div>
          )}
        </>
      )}

      <PreCloseTable
        open={openConfirmClose}
        onPreClose={finishTable}
        onClose={() => setOpenConfirmClose(false)}
      />
      {/* <CloseTable
        open={openCloseTable}
        onClose={() => setOpenCloseTable(false)}
        onCloseDesk={() => {
          setOpenCloseTable(false);
          setCloseDesk(true);
        }}
        onCloseWaiter={() => {
          setOpenCloseTable(false);
          setCloseWaiter(true);
        }}
      /> */}
      <Drawer open={openDeskSuccess} alreadyDrawer direction="bottom-center">
        <div className="d-flex justify-center mb-20">
          <CheckCircle2 />
        </div>
        <p className={styles.alertTitle}>A mesa foi fechada</p>
        <p className={styles.alertSubtitle}>
          Você pode ir ao balcão realizar o seu pagamento.
        </p>
        <Button
          design="primary"
          className="w-100"
          onClick={() => {
            setCloseDesk(true);
            setOpenDeskSuccess(false);
          }}
        >
          Voltar para mesa
        </Button>
      </Drawer>

      <Drawer open={!!error} alreadyDrawer direction="bottom-center">
        <p className={styles.alertTitle}>{error?.title}</p>
        <p className={styles.alertSubtitle}>{error?.description}</p>
        <Button
          design="primary"
          className="w-100"
          onClick={() => setError(null)}
        >
          Ok
        </Button>
      </Drawer>
    </div>
  );
}
