import { Request } from "@konv/http";
import { Builder } from "@olga-food/schemas/lib/classes/schemas/catalog/Builder";

export class CatalogAPI {
	static async request({
		endpoint = "/",
		method = "GET",
		headers,
		query,
		data,
	}) {
		return await Request.request({
			endpoint: endpoint,
			method: method,
			headers: headers,
			service: "catalog",
			ignoreVersion: true,
			params: query,
			data: data,
		});
	}

	static removeHiddensAndASized(catalog) {
		//Removendo do cardapio tudo o que está oculto
		Builder.executeOnSchema(catalog, (item) => {
			item.sizes = item.sizes?.filter((s) => s.show);
			item.items = item.items?.filter((i) => i.show);
			item.edges = item.edges?.filter((e) => e.show);
			item.doughs = item.doughs?.filter((d) => d.show);
			item.extra_ingredients = item.extra_ingredients?.filter((e) => e.show);
		})

		//Removendo do cardapio tudo que não tenha tamanho definido
		Builder.executeOnSchema(catalog, (item) => {
			if (['category', 'option'].includes(item.type)) {
				return;
			}

			if (item.sizes?.length == 0) {
				item.show = false;
			}
		});

		return catalog;
	}

	static async getS3Catalog(environmentAlias, service) {
		try {
			const response = await fetch(`${process.env.NEXT_PUBLIC_CDN_ENVIRONMENT_CATALOG}${environmentAlias}/${service}.json?t=${Date.now()}`);
			const catalog = CatalogAPI.removeHiddensAndASized(await response.json());
			return CatalogAPI.prepareCatalog(catalog);
		} catch (err) {
			console.log(err);
			return { type: "catalog", items: [] };
		}
	}

	static async getEcleticaCatalog (environmentAlias) {
		try {
			const response = await fetch(`${process.env.NEXT_PUBLIC_CDN_ENVIRONMENT_CATALOG}${environmentAlias}/OPEN_TABLE.json?t=${Date.now()}`);
			const catalog = await response.json();
			return CatalogAPI.prepareCatalog(catalog);
		} catch (err) {
			console.log(err);
			return { type: "catalog", items: [] };
		}
	}

	static async getEcleticaProducts (environmentAlias) {
		try {
			const response = await fetch(`${process.env.NEXT_PUBLIC_CDN_ENVIRONMENT_CATALOG}${environmentAlias}/ECLETICA/products.json?t=${Date.now()}`);
			const products = await response.json();
			return products;
		} catch (err) {
			console.log(err);
			return [];
		}
	}

	static async getEcleticaCombinations (environmentAlias) {
		try {
			const response = await fetch(`${process.env.NEXT_PUBLIC_CDN_ENVIRONMENT_CATALOG}${environmentAlias}/ECLETICA/combinations.json?t=${Date.now()}`);
			const combinations = await response.json();
			return combinations;
		} catch (err) {
			console.log(err);
			return [];
		}
	}

	static prepareCatalog (catalog) {
		for (const category of catalog.items) {
			if (category.mode == 'step') {
				for (const steps of category.items) {
					const questions = steps?.items || [];
					steps.items = CatalogAPI.prepareQuestionOptions(questions);
				}
				continue;
			}

			if (category.mode == 'pizza') {
				const newItems = [];
				//Tamanho como produto
				for (const size of category.sizes) {
					newItems.push({
						id: size.id,
						type: 'pizza',
						name: size.name,
						ref_id: size.ref_id,
						sizes: [size],
						show: true,
						visible: true,
						quantity: 1,
						items: category.items.map((flavor) => {
							return {
								...flavor,
								sizes: flavor.sizes.filter((s) => s.ref_id == size.ref_id)
							};
						}),
						extra_ingredients: category.extra_ingredients.map((extra) => {
							return {
								...extra,
								quantity: 0,
								sizes: extra.sizes.filter((s) => s.ref_id == size.ref_id)
							};
						}),
						doughs: category.doughs.map((dough) => {
							return {
								...dough,
								sizes: dough.sizes.filter((s) => s.ref_id == size.ref_id)
							};
						}),
						edges: category.edges.map((edge) => {
							return {
								...edge,
								sizes: edge.sizes.filter((s) => s.ref_id == size.ref_id)
							};
						}),
					})
				}

				//Sabores como destaques
				for (const flavor of category.items) {

					if (!flavor.highlight) {
						continue;
					}

					newItems.push({
						type: 'pizza',
						id: flavor.id,
						name: flavor.name,
						fixedFlavor: flavor.id,
						selectedFlavors: [flavor.id],
						image: flavor.image || null,
						highlight: true,
						description: flavor.description,
						ref_id: null,
						sizes: [...flavor.sizes],
						show: true,
						visible: true,
						quantity: 1,
						items: category.items.map((f) => {
							return { ...f, selected: f.id == flavor.id }
						}),
						extra_ingredients: [...category.extra_ingredients.map(extra => ({ ...extra, quantity: 0 }))],
						doughs: [...category.doughs],
						edges: [...category.edges]
					})
				}

				category.items = newItems
			}
		}

		return catalog;
	}

	static prepareQuestionOptions(questions = []) {
		return questions = questions.map((question) => {
			const options = (question.items || []).map((option) => ({
				...option,
				quantity: 0,
				items: option?.items?.length === 0 ? [] : CatalogAPI.prepareQuestionOptions(option.items)
			}));
			
			return {
				...question,
				items: options
			}
		});
	}

	static async getS3Configurations(alias) {
		try {
			const config_url = `${process.env.REACT_APP_CDN_ENVIRONMENT_CONFIGURATIONS}/${alias}.json?t=${Date.now()}`;
			return await fetch(config_url).then((r) => {
				if (r === null) {
					return r;
				}
				r.json();
			});
		} catch (err) {
			return err;
		}
	}
}
