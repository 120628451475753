import { useEffect, useState } from "react";

import Link from "next/link";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";

import styles from "styles/layout/header.module.scss";

import {
  Bag,
  ChevronDown,
  CommandLight,
  MapMarkerB,
  Reload,
  TableRegular,
  User,
} from "@/shared/components/Icons";
import { Button, Drawer } from "@/shared/components";

import { CatalogHelpers, Currency } from "shared/tools";
import { OrderStorage } from "@/storage/OrderStorage";

const Club = dynamic(() => import("@/shared/containers/Benefits/Club"));
const PaymentMethods = dynamic(() =>
  import("@/shared/containers/Payment/PaymentMethods")
);
const OrderDetails = dynamic(() =>
  import("@/shared/containers/Order/OrderDetails")
);
const OrderTracking = dynamic(() =>
  import("@/shared/containers/Order/OrderTracking")
);
const Cart = dynamic(() => import("@/shared/containers/Cart/Cart"));
const Addressess = dynamic(() =>
  import("@/shared/containers/Address/Addressess")
);
const CustomerData = dynamic(() =>
  import("@/shared/containers/Account/CustomerData")
);
const LoginOrRegister = dynamic(() =>
  import("@/shared/containers/Account/LoginOrRegister")
);
const Help = dynamic(() => import("@/shared/containers/Help"));

import classNames from "classnames/bind";
import { Customer } from "@/shared/core/Customer";
import { Environment } from "@/shared/core/Environment";
import { OlgaFoodSession } from "shared/tools/OlgaFoodSession";
import useEventListener from "shared/hooks/useEventListener";
import { Store, StoreEvents } from "@/shared/core/Store";
import { Brand } from "@/shared/core/Brand";
import { Holding } from "@/shared/core/Holding";
import { Address } from "@/shared/core/Address";
import { DataLayer } from "@/shared/tools/DataLayer";
import {
  ForkKnifeRegular,
  GiftRegular,
  HomeRegular,
  Invoice,
} from "@/shared/components/Icons";
import Profile from "@/shared/containers/Account/Profile";
import ReceiveOnLocal from "@/shared/containers/Home/ReceiveOnLocal";
import Table from "@/shared/containers/Order/Table";
import CartReceiveOn from "@/shared/containers/Cart/CartReceiveOn";
import { OpenTableAPI } from "@/api/OpenTableAPI";

import { Table as TableCore } from "@/shared/core/Table";
import { Flows } from "@/shared/core/Enum";

const css = classNames.bind(styles);

const formatAddress = (address) => {
  return (
    address &&
    `${address.address}, ${address.number}, ${address.region} - ${address.city} - ${address.state}`
  );
};

export default function Header() {
  const router = useRouter();
  const [timestamp, setTimestamp] = useState(Date.now());

  const [openCart, setOpenCart] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);
  const [openAddressess, setOpenAddressess] = useState(false);
  const [openUserData, setOpenUserData] = useState(false);
  const [openLoginOrRegister, setOpenLoginOrRegister] = useState(false);
  const [openOrderDetails, setOpenOrderDetails] = useState(false);
  const [openOrderTracking, setOpenOrderTracking] = useState(false);
  const [openClub, setOpenClub] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [isLogged, setIsLogged] = useState(false);
  const [customerName, setCustomerName] = useState(null);
  const [address, setAddress] = useState(Address.current);
  const [openPaymentMethods, setOpenPaymentMethods] = useState(false);
  const [openCommand, setOpenCommand] = useState(false);
  const [cartCatalog, setCartCatalog] = useState({ selected: true, items: [] });
  const [openReceiveOn, setOpenReceiveOn] = useState(false);
  const [table, setTable] = useState([]);
  const [quantityItemsTable, setQuantityItemsTable] = useState(null);
  const [openConfirmBackToHome, setOpenConfirmBackToHome] = useState(false);
  const [isDigitalMenu, setIsDigitalMenu] = useState(false);

  const selectedCatalog = { ...cartCatalog, quantity: 1, selected: true };
  const cartItemsCount = cartCatalog.items.reduce(
    (t, i) => t + (i.quantity || 1),
    0
  );

  const loadTableItems = async () => {
    const environmentId = Store.id;
    const tableId = TableCore.id;

    const tableDetailsReq = await OpenTableAPI.getTableDetails(
      environmentId,
      tableId
    );
    const tableDetails = tableDetailsReq.getData({});
    setTable(tableDetails);
  };

  useEffect(() => {
    const externalId = TableCore.externalId;
    if (externalId === TableCore.isDigitalMenu) setIsDigitalMenu(true);
    else setIsDigitalMenu(false);

    loadTableItems();
  }, []);

  useEffect(() => {
    if (openCommand) {
      DataLayer.push({
        event: "page_view",
        page_title: `Visualizar mesa | ${TableCore.name}`,
      });
    }
  }, [openCommand]);

  useEffect(() => {
    if (table?.produtos?.length > 0) {
      const groupedCombos = table.produtos.reduce(
        (result, item) => ({
          ...result,
          [item.cod_reg]: [...(result[item.cod_reg] || []), item],
        }),
        {}
      );

      setQuantityItemsTable(Object.keys(groupedCombos)?.length);
    }
  }, [table]);

  const onOrderChanged = () => {
    const order = OrderStorage.getOrder();

    if (order && order.cart && order.cart.catalog) {
      setCartCatalog(order.cart.catalog);
    }
  };

  const loadUser = () => {
    if (OlgaFoodSession.hasSession()) {
      setIsLogged(true);
      setOpenLoginOrRegister(false);
      setCustomerName(Customer.name);
    } else {
      setIsLogged(false);
      setOpenLoginOrRegister(false);
      setCustomerName(null);
    }
  };

  const onOrderUpdated = () => {
    const { delivery } = OrderStorage.getOrder();
    setAddress(delivery?.address || null);
    onOrderChanged();
  };

  // const onCustomerUpdated = () => {
  // 	setAddress(Customer.getCustomerAddress());
  // };

  useEventListener(StoreEvents.STORE_CHANGED, () => {
    setTimestamp(Date.now());
    const { delivery } = OrderStorage.getOrder();
    setAddress(Address.current || delivery?.address);
  });

  useEventListener("orderUpdated", onOrderUpdated);
  // useEventListener("customerChanged", onCustomerUpdated);
  useEventListener("orderChanged", onOrderChanged);

  useEffect(() => {
    loadUser();
    onOrderUpdated();
    onOrderChanged();
  }, []);

  const setOrder = (order) => {
    setSelectedOrder(order);
    setOpenOrderDetails(true);
  };

  const isHoldingDomain = Holding.alias && !Brand.alias;

  let selectedEnvironment = null;
  if (typeof window !== "undefined") {
    selectedEnvironment = Brand.alias;
  }

  const orderFlow = OrderStorage.flow;

  const backToHome = () => {
    OrderStorage.clearCartItems();
    setOpenConfirmBackToHome(false);

    router.push("/");
  };

  return (
    <>
      {!isDigitalMenu ? (
        <div
          className={css({
            headerContainerDelivery: orderFlow === Flows.DELIVERY,
            headerContainerReceiveOn: orderFlow !== Flows.DELIVERY,
          })}
        >
          <header className={styles.header}>
            {orderFlow === Flows.DELIVERY && (
              <div className={styles.addressContainer}>
                <div
                  className={styles.address}
                  onClick={() => setOpenAddressess(true)}
                >
                  <div className="d-flex align-center">
                    <MapMarkerB />
                    {address && (
                      <p>
                        {address.alias ? address.alias : formatAddress(address)}
                      </p>
                    )}
                    {!address && <p>Endereço não definido</p>}
                  </div>
                  <div className={styles.icon}>
                    <ChevronDown />
                  </div>
                </div>
              </div>
            )}

            <nav>
              <ul>
                {!isHoldingDomain && (
                  <>
                    {/* <Link href="/">
                  <li>
                    <HomeRegular />
                    Início
                  </li>
                </Link> */}

                    <Link
                      href={
                        selectedEnvironment
                          ? `/${selectedEnvironment}/cardapio`
                          : "/cardapio"
                      }
                    >
                      <li
                        className={css({
                          active:
                            router.asPath ===
                              `/${selectedEnvironment}/cardapio` ||
                            router.pathname === "/cardapio",
                        })}
                      >
                        <ForkKnifeRegular />
                        Cardápio
                      </li>
                    </Link>

                    {orderFlow === Flows.OPEN_TABLE && (
                      <li
                        className={styles.item}
                        onClick={() => setOpenCommand(true)}
                      >
                        <div className={styles.icon}>
                          <TableRegular />
                          {cartItemsCount ? (
                            <span className={styles.quantity}>
                              {cartItemsCount}
                            </span>
                          ) : null}
                        </div>
                        Minha conta
                      </li>
                    )}

                    {/* <li onClick={() => setOpenConfirmBackToHome(true)}>
                      <div className={styles.iconReload}>
                        <Reload />
                        Trocar mesa
                      </div>
                    </li> */}
                    
                    <div className={styles.hairline} />

                    <div
                      className={styles.bag}
                      onClick={() => {
                        setOpenCart(true);
                      }}
                    >
                      <div className="d-flex align-center h-100">
                        <Bag />
                        <span className={styles.quantity}>{cartItemsCount}</span>
                        <p className={styles.total}>
                          {Currency.formatCurrency(
                            CatalogHelpers.calculateSelectedItemPrice(
                              selectedCatalog
                            )
                          )}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </ul>
            </nav>
          </header>
        </div>
      ) : null}

      <Profile open={openProfile} onClose={() => setOpenProfile(false)} />

      {orderFlow === Flows.DELIVERY && (
        <Cart
          open={openCart}
          onClose={() => setOpenCart(false)}
          setOpenBenefits={() => setOpenBenefits(true)}
          selectDeliveryAddress={() => setOpenAddressess(true)}
          selectPaymentMethod={() => setOpenPaymentMethods(true)}
          onLoginOrRegister={() => setOpenLoginOrRegister(true)}
          onCheckout={(order) => {
            setOpenCart(false);
            setOrder(order);
          }}
        />
      )}

      {orderFlow !== Flows.DELIVERY && (
        <CartReceiveOn
          open={openCart}
          onClose={() => setOpenCart(false)}
          onOpenTable={() => setOpenCommand(true)}
        />
      )}

      <Drawer
        open={openCommand}
        onClose={() => setOpenCommand(false)}
        title="Sua mesa"
        subtitle={TableCore.name}
      >
        <Table onClose={() => setOpenCommand(false)} />
      </Drawer>

      <Drawer
        open={openHelp}
        onClose={() => setOpenHelp(false)}
        title="Ajuda"
        showCloseButton
        alreadyDrawer={openProfile}
      >
        <Help />
      </Drawer>

      <PaymentMethods
        open={openPaymentMethods}
        onlyOnline={true}
        onClose={() => setOpenPaymentMethods(false)}
      />

      <ReceiveOnLocal
        open={openReceiveOn}
        onClose={() => setOpenReceiveOn(false)}
        inCatalog
      />

      <CustomerData
        open={openUserData}
        onClose={() => setOpenUserData(false)}
        setLogout={(status) => {
          setOpenProfile(status);
          setIsLogged(null);
        }}
      />

      <Addressess
        open={openAddressess}
        onClose={() => setOpenAddressess(false)}
        alreadyDrawer={openCart}
      />

      <OrderDetails
        open={openOrderDetails}
        onClose={() => {
          setOpenOrderDetails(false);
          setOpenOrderTracking(false);
        }}
        selectedOrder={selectedOrder}
        onTracking={() => setOpenOrderTracking(true)}
        alreadyDrawer
      />

      <OrderTracking
        open={openOrderTracking}
        onClose={() => {
          setOpenOrderTracking();
        }}
        selectedOrder={selectedOrder}
      />

      <LoginOrRegister
        open={openLoginOrRegister}
        onClose={() => setOpenLoginOrRegister(false)}
        setIsLogged={() => loadUser()}
        alreadyDrawer={openCart}
      />

      <Club open={openClub} onClose={() => setOpenClub(false)} />

      <div
        className={`${styles.fade} ${openProfile ? styles.in : styles.out}`}
        onClick={() => setOpenProfile(false)}
      />

      <Drawer
        open={openConfirmBackToHome}
        alreadyDrawer
        direction="bottom-center"
      >
        <p className={styles.alertTitle}>Atenção</p>
        <p className={styles.alertSubtitle}>
          Ao trocar a mesa, seu carrinho será limpo e a mesa será perdida.
          Deseja continuar?
        </p>
        <Button
          design="primary"
          className="w-100 mb-10"
          onClick={() => setOpenConfirmBackToHome(false)}
        >
          Cancelar
        </Button>
        <Button
          design="secondary"
          className="w-100"
          onClick={() => backToHome()}
        >
          Sim, desejo trocar
        </Button>
      </Drawer>
    </>
  );
}
