import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import NetLogicCartItem from "@/shared/containers/Cart/NetLogicCartItem";
import { Brand } from "@/shared/core/Brand";
import { isDesktop } from "react-device-detect";

export default function NetLogicCart({ table, onClose, hideAddMoreItems }) {
  const [selectedEnvironment, setSelectedEnvironment] = useState(null);

  const router = useRouter();

  useEffect(() => {
    setSelectedEnvironment(Brand.alias ? Brand.alias : null);
  }, []);

  const renderCartItems = () => {
    return (
      <NetLogicCartItem
        onClose={() => close()}
        order={table}
        hideAddMoreItems={hideAddMoreItems}
      />
    );
  };

  if (!table) {
    return null;
  }

  const close = () => {
    if (isDesktop) return onClose && onClose();

    const newPath = selectedEnvironment
      ? `/${selectedEnvironment}/cardapio`
      : "/cardapio";
    router.push(newPath);
  };

  return table?.menuItems.length > 0 && renderCartItems();
}
