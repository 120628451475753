import { Builder } from "@olga-food/schemas/lib/classes/schemas/catalog/Builder";
import { Currency } from "./Currency";
import { OMath } from "./OMath";

export class CatalogHelpers {
  static executeOnSchema(item, callback) {
    for (let field of ["items", "extra_ingredients", "sizes"]) {
      if (item[field] && item[field].length > 0) {
        for (let children of item[field]) {
          CatalogHelpers.executeOnSchema(children, callback);
          callback(children, item, field);
        }
      }
    }
  }

  static calculateSelectedItemPrice(item) {
    if (item.type !== "pizza") {
      const props = ["items", "sizes", "extra_ingredients"];
      // Prevenção de problemas com ponto flutuante: R$ 9.99 => 999
      const price = Math.round((item.price || 0) * 100);
      let total = (price * (item.selected !== false && item.quantity > 0 ? 1 : 0));

      for (const key of props) {
        if (item[key] && item[key].length > 0) {
          for (const subItem of item[key]) {
            total += CatalogHelpers.calculateSelectedItemPrice(subItem) * 100;
          }
        }
      }

      // console.log({ name: item.name, total, price });
      // Prevenção de problemas com ponto flutuante: R$ 999 => 9.99
      return +OMath.div(OMath.times(total, item.quantity), 100).toFixed(2);
    } else {
      const pizza = Builder.mountSchema(JSON.parse(JSON.stringify(item)));
      return pizza.getPrice();
    }
  }

  static clearUnselectedItems(item) {
    const props = ["items", "sizes", "extra_ingredients", "doughs", "edges"];
    for (const key of props) {
      if (item[key] && item[key].length > 0) {
        for (const subItemIndex in item[key]) {
          item[key][subItemIndex] = CatalogHelpers.clearUnselectedItems(
            item[key][subItemIndex]
          );
        }

        item[key] = item[key].filter((i) => i.selected !== false);
        if (item[key].length > 0) {
          item.selected = true;
        }
      }
    }
    return item;
  }

  static mountPrice(item) {
    if (item.price > 0) {
      return Currency.formatCurrency(Number(item.price));
    }

    if (item.price >= 0 && item.items?.length === 0) {
      const minValue = (item.sizes || []).reduce((prev, current) =>
        (prev.price || Infinity) < (current.price || Infinity) ? prev : current
      );
      return Currency.formatCurrency(Number(minValue.price));
    }

    if (item.sizes.length > 0) {
      const minValue = (item.sizes || []).reduce((prev, current) =>
        (prev.price || Infinity) < (current.price || Infinity) ? prev : current
      );

      if (minValue.price === 0) {
        if (item.items.length > 0) {
          let totalPrice = 0;
          (item.items || []).forEach((subitem) => {
            if (subitem.items?.length > 0) {
              const minSubitemValue = (subitem.items || []).reduce(
                (prev, current) =>
                  (prev.price || Infinity) < (current.price || Infinity)
                    ? prev
                    : current
              );
              totalPrice = OMath.sum(
                totalPrice,
                OMath.times(minSubitemValue.price, subitem.min)
              );
            }
          });

          return Currency.formatCurrency(Number(totalPrice));
        }
      }

      return Currency.formatCurrency(Number(minValue.price));
    }
  }

  static mountOldPrice(item) {
    if (item.old_price > 0) {
      return Currency.formatCurrency(Number(item.old_price));
    }

    if (item.old_price >= 0 && item.sizes.length === 0) {
      return Currency.formatCurrency(Number(item.old_price));
    }

    if (item.sizes.length > 0) {
      const minValue = Math.min(
        ...((item.sizes || []).map((s) => s.old_price || 0) || [0])
      );

      const minPrices = item.items.map((question) => {
        if (!question.min) {
          return 0;
        }

        const minOptionPrice =
          question.items.length > 0
            ? Math.min(
                ...(question.items.map(
                  (options) => options.old_price * question.min
                ) || [0])
              )
            : 0;
        return minOptionPrice;
      });

      if (Number(minValue + minPrices.reduce((s, v) => s + v, 0)) === 0)
        return null;

      return Currency.formatCurrency(
        Number(minValue + minPrices.reduce((s, v) => s + v, 0))
      );
    }
  }
}
