import { useCallback, useLayoutEffect } from "react";

import classnames from "classnames";

import Modal from "react-modal";

import { isMobile } from "react-device-detect";
import { Times } from "shared/components/Icons";
import { ArrowLeft } from "./Icons";

let body;
let scrollPosition;

if (typeof window != "undefined") {
  body = document.querySelector("body");
  scrollPosition = 0;
}

export const Drawer = ({
  open,
  title,
  subtitle,
  onClose,
  direction = "right",
  backButton,
  showBackButton,
  showCloseButton,
  withoutPadding,
  withoutScroll,
  withoutTransition,
  withoutFade,
  backgroundColor = "#fff",
  children,
  alreadyDrawer,
  showJumpButton,
  jumpButton,
}) => {
  const handleCloseDrawer = useCallback(
    (status) => onClose && onClose(status),
    [onClose]
  );

  const handleBackButton = useCallback(() => {
    if (typeof backButton == "function") {
      backButton();
    } else {
      handleCloseDrawer(!open);
    }
  }, [open, backButton, handleCloseDrawer]);

  useLayoutEffect(() => {
    if (open && isMobile) {
      scrollPosition = window.scrollY;

      if (["top-full", "bottom", "right"].includes(direction)) {
        setTimeout(() => {
          body.style.overflow = "hidden";
          body.style.position = "fixed";
          body.style.top = `-${scrollPosition}px`;
          body.style.width = "100%";
        }, 0);
      }
    }
  }, [open, handleCloseDrawer, direction]);

  useLayoutEffect(() => {
    if (open && isMobile) {
      scrollPosition = window.scrollY;

      if (["top-full", "bottom", "right"].includes(direction)) {
        setTimeout(() => {
          body.style.overflow = "hidden";
          body.style.position = "fixed";
          body.style.top = `-${scrollPosition}px`;
          body.style.width = "100%";
        }, 0);
      }

      const handleBackButton = (event) => {
        event.preventDefault();
        handleCloseDrawer();
      };

      window.history.pushState(null, "", window.location.pathname);
      window.addEventListener("popstate", handleBackButton);
    }

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [open]);

  useLayoutEffect(() => {
    if (!open && isMobile) {
      body.style.removeProperty("overflow");
      body.style.removeProperty("position");
      body.style.removeProperty("top");
      body.style.removeProperty("width");
      window.scrollTo(0, scrollPosition);
    }
  }, [open]);

  const onCloseDrawer = () => {
    document.body.classList.remove("ReactModal__Body--open");
  };

  return (
    <Modal
      isOpen={open}
      autoFocus={false}
      ariaHideApp={false}
      closeTimeoutMS={400}
      onRequestClose={() => handleCloseDrawer(!open)}
      className={{
        base: "drawer-base",
        afterOpen: "drawer-base_after-open",
        beforeClose: "drawer-base_before-close",
      }}
      overlayClassName={{
        base: "overlay-base",
        afterOpen: "overlay-base_after-open",
        beforeClose: "overlay-base_before-close",
      }}
      shouldReturnFocusAfterClose={false}
      preventScroll
      onAfterClose={() => !alreadyDrawer && onCloseDrawer()}
    >
      <div
        className={classnames({
          fade: true,
          withBackground: !alreadyDrawer,
          withoutTransition: withoutTransition,
          withoutFade: withoutFade,
        })}
        onClick={() =>
          direction !== "bottom-center" && handleCloseDrawer(!open)
        }
      />
      <div
        className={classnames({
          drawer: true,
          [direction]: true,
          withoutTransition: withoutTransition,
        })}
        style={{ backgroundColor: backgroundColor }}
      >
        {(showBackButton || showCloseButton || title) && (
          <div className="header">
            {showBackButton && (
              <div className="back" onClick={() => handleBackButton()}>
                <ArrowLeft />
              </div>
            )}
            <div className="d-flex flex-col align-center">
              {title && <p className="title">{title}</p>}
              {subtitle && <span className="subtitle">{subtitle}</span>}
            </div>
            {showCloseButton && (
              <div className="close" onClick={() => handleCloseDrawer(!open)}>
                <Times />
              </div>
            )}
            {showJumpButton && <div className="jump">{jumpButton}</div>}
          </div>
        )}
        <div
          className={classnames({
            content: true,
            withoutPadding: withoutPadding,
            withoutScroll: withoutScroll,
          })}
        >
          {children}
        </div>
      </div>
    </Modal>
  );
};
