import styles from "styles/containers/cart-item.module.scss";

import { Currency } from "shared/tools";

import { Button } from "shared/components";
import { OMath } from "@/shared/tools/OMath";

const NetLogicItem = ({ product, cartItemIndex }) => {
  const getSubItemsOverview = () => {
    return (
      <div className={styles.question}>
        <span>
          {product.condiments
            .map((subItem, index) => `${subItem.quantity}x ${subItem.name}`)
            .join(", ")}
        </span>
      </div>
    );
  };

  return (
    <div className={styles.item}>
      {/* <div className={styles.picture}>
        {product.image ? (
          <Image
            src={`${process.env.NEXT_PUBLIC_CDN_ASSETS}/${product.image}`}
            alt=""
            layout="fill"
            sizes="100vw"
          />
        ) : (
          <div className={styles.noImage}>
            <NoImage />
          </div>
        )}
      </div> */}
      <div className={styles.details}>
        <div className="d-flex align-center">
          <p className={styles.qty}>{product.quantity}x</p>
          <p className={styles.itemName}>{product?.name}</p>
        </div>

        {product.condiments.length > 0 && (
          <div className={styles.questions}>{getSubItemsOverview()}</div>
        )}

        <div className={styles.total}>
          <p className={styles.value}>
            {Currency.formatCurrency(product.unitPrice)}
          </p>
          {product.itemDiscounts && product.itemDiscounts[0] && (
            <p className={styles.discount}>
              Desconto de{" "}
              {Currency.formatCurrency(
                product.quantity * product.itemDiscounts[0].total
              )}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default function NetLogicCartItem({ order, onClose, hideAddMoreItems }) {
  const manualServiceFee = order.totals?.serviceChargeTotal || 0;
  const autoServiceFee = order.totals?.autoServiceChargeTotal || 0;
  const serviceFee = +OMath.sum(manualServiceFee, autoServiceFee).toFixed(2);

  return (
    <div className={styles.netLogicItems}>
      <div className={styles.totalsContainer}>
        <ul>
          <li>
            <p>Valor total</p>
            <span>
              {Currency.formatCurrency(
                order.totals.subtotal +
                  Math.abs(order.totals?.subtotalDiscountTotal || 0)
              )}
            </span>
          </li>

          {serviceFee ? (
            <li>
              <p>Taxa de serviço</p>
              <span>{Currency.formatCurrency(serviceFee)}</span>
            </li>
          ) : null}

          {order.totals?.subtotalDiscountTotal ? (
            <li>
              <p>Total de descontos</p>
              <span>
                {Currency.formatCurrency(order.totals.subtotalDiscountTotal)}
              </span>
            </li>
          ) : null}
          <li>
            <p>Total</p>
            <span className={styles.total}>
              {Currency.formatCurrency(order.totals.totalDue)}
            </span>
          </li>
        </ul>
      </div>

      {(order?.menuItems || []).map((item, index) => (
        <NetLogicItem
          key={`cart-item-${Math.random()}`}
          product={item}
          cartItemIndex={index}
        />
      ))}

      {!hideAddMoreItems && (
        <div className={styles.addItems}>
          <Button onClick={() => onClose && onClose()}>
            Adicionar mais itens
          </Button>
        </div>
      )}
    </div>
  );
}
