import { CatalogAPI } from "api/CatalogAPI";
import { Environment } from "./Environment";
import { Store } from "./Store";

import * as Availability from "@olga-food/schemas/lib/classes/schemas/catalog/Availability";
export class Catalog {
  static memorizeCatalog(alias, catalog) {
    localStorage.setItem(`of-catalog-${alias}`, JSON.stringify(catalog));
  }

  static getMemorizedCatalog(alias) {
    const catalogString = localStorage.getItem(`of-catalog-${alias}`);
    return catalogString
      ? JSON.parse(catalogString)
      : { type: "catalog", items: [] };
  }

  static async getCurrentCatalog() {
    const selectedEnvironment = Environment.getCurrentEnvironment();

    if (selectedEnvironment.type == "HOLDING") {
      return { type: "catalog", items: [] };
    }

    const catalogResponse = await CatalogAPI.getS3Catalog(
      selectedEnvironment.alias,
      "INDOOR"
    );
    Catalog.memorizeCatalog(selectedEnvironment.alias, catalogResponse);
    return catalogResponse;
  }

  static getItemsByParam(schema, param, value) {
    const list = schema[param] == value ? [schema] : [];
    for (const field of [
      "items",
      "extra_ingredients",
      "edges",
      "doughs",
      "sizes",
    ]) {
      if (schema[field] && schema[field].length > 0) {
        schema[field].forEach((item) => {
          list.push(...(Catalog.getItemsByParam(item, param, value) || []));
        });
      }
    }
    return list;
  }

  static preparePizzaProductToUpdate(product) {
    const currentCatalog = Catalog.getMemorizedCatalog(Store.alias);
    const findedItems = this.getItemsByParam(currentCatalog, "id", product.id);
    const pizza = findedItems.find((i) => i.type == "pizza");
    const newPizza = JSON.parse(JSON.stringify(pizza));

    //Preencher os tamanhos da pizza
    newPizza.ref_id = product.ref_id;
    newPizza.selectedFlavors = product.selectedFlavors;
    newPizza.quantity = product.quantity;

    //Corrigir tamanhos
    for (const size of newPizza.sizes) {
      size.selected = size.ref_id == product.ref_id;
    }

    //Corrigir bordas
    for (const edge of newPizza.edges) {
      for (const oldEdge of product.edges) {
        if (edge.id != oldEdge.id) {
          continue;
        }

        edge.selected = oldEdge.selected;
        edge.quantity = +edge.selected;
        edge.sizes?.forEach((s) => {
          s.selected = s.ref_id == product.ref_id;
        });
        break;
      }
    }

    //Corrigir massas
    for (const dough of newPizza.doughs) {
      for (const oldDough of product.doughs) {
        if (dough.id != oldDough.id) {
          continue;
        }

        dough.selected = oldDough.selected;
        dough.quantity = +dough.selected;
        dough.sizes?.forEach((s) => {
          s.selected = s.ref_id == product.ref_id;
        });
        break;
      }
    }

    for (const extra of newPizza.extra_ingredients) {
      for (const oldExtra of product.extra_ingredients) {
        if (extra.id != oldExtra.id) {
          continue;
        }

        extra.selected = oldExtra.quantity > 0;
        extra.quantity = oldExtra.quantity;
        extra.sizes?.forEach((s) => {
          s.selected = s.ref_id == product.ref_id;
        });
      }
    }

    return newPizza;
  }

  static updateCatalogAvailability(currentCatalog) {
    const newItems = currentCatalog.items.map((category) => {
      category.available =
        !!category.show && Availability.isAvailable(category, null, -3);

      const products = category.items.map((product) => {
        product.available =
          !!product.show && Availability.isAvailable(product, null, -3);
        return product;
      });

      const hasActiveProducts = products.some(
        (product) => product.available === true
      );
      if (!hasActiveProducts) {
        category.available = false;
      }

      return {
        ...category,
        items: products,
      };
    });

    return {
      ...currentCatalog,
      items: newItems,
    };
  }
}
