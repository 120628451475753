import dayjs from "dayjs";

// Classe centralizadora da obtenção de datas e horários
export class Time {
  static get week_day() {
    return Time.getInstance().day();
  }

  /**
   *
   * @param  {...any} args
   * @returns {dayjs.Dayjs}
   */
  static getInstance(...args) {
    return dayjs(...args);
  }

  static format(format) {
    return Time.getInstance().format(format);
  }

/*************  ✨ Codeium Command ⭐  *************/
/******  d7868046-00de-4007-93cf-b4187383df29  *******/  static getDate(date) {
    return Time.getInstance(date);
  }
}
