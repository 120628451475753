import { OpenTableAPI } from "@/api/OpenTableAPI";
import { Button, Drawer } from "@/shared/components";
import { Store } from "@/shared/core/Store";
import { Table } from "@/shared/core/Table";
import { useState } from "react";

import styles from "@/styles/containers/pre-close-table.module.scss";

export function PreCloseTable({ open, onClose, onPreClose }) {
  const [loading, setLoading] = useState(false);

  const preClose = async () => {
    setLoading(true);

    await onPreClose();

    setLoading(false);
  }
    
  return (
    <Drawer
      open={open}
      alreadyDrawer
      onClose={() => onClose()}
      direction="bottom"
    >
      <p className={styles.alertTitle}>Pedir conta</p>
      <p className={styles.alertSubtitle}>
        Você tem certeza que deseja fechar sua conta e realizar o pagamento?
      </p>
      <Button
        design="primary"
        className="w-100 mb-10"
        disabled={loading}
        loading={loading}
        onClick={() => preClose()}
      >
        Pedir conta
      </Button>
      <Button
        design="secondary"
        className="w-100"
        onClick={() => onClose()}
      >
        Cancelar
      </Button>

    </Drawer>
  );
}
