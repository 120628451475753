import { OrderStorage } from "storage/OrderStorage";

import Step from "shared/containers/Product/Step";
import Drink from "shared/containers/Product/Drink";
import Pizza from "shared/containers/Product/Pizza";
import { DataLayer } from "shared/tools/DataLayer";
import { OMath } from "shared/tools/OMath";
// import { EcleticaCatalog } from "@/shared/core/EcleticaCatalog";
// import EcleticaStep from "./EcleticaStep";

export default function ProductDetails({ product, onClose, cartItemIndex }) {
  const calculateItemPrice = (item, totalValue) => {
    const props = ["items", "sizes", "extra_ingredients", "doughs", "edges"];

    let total =
      Math.round((item.price || 0) * 100) *
      (item.selected !== false && item.quantity > 0 ? 1 : 0);
    for (const key of props) {
      if (item[key] && item[key].length > 0) {
        for (const subItem of item[key]) {
          total += calculateItemPrice(subItem) * 100;
        }
      }
    }

    if (totalValue) return +(OMath.div(OMath.times(total, item.quantity), 100)).toFixed(2);

    return +(OMath.div(total, 100)).toFixed(2);
  };

  const onSave = (product) => {
    if (cartItemIndex !== null) {
      OrderStorage.updateCartItem(cartItemIndex, product);
    } else {
      OrderStorage.addItemToCart(product);
    }

    const getAddons = (item) => {
      let addons = [];

      item.items?.map((a) => {
        a.items?.filter((b) => b.selected && addons.push(b.name));
      });

      return addons.join(" | ");
    };

    const productDataLayer = {
      event: "add_to_cart",
      ecommerce: {
        currency: "BRL",
        value: calculateItemPrice(product, true),
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            quantity: product.quantity,
            price: calculateItemPrice(product),
            item_variant: getAddons(product),
          },
        ],
      },
    };

    DataLayer.push({ ecommerce: null });
    DataLayer.push(productDataLayer);


    onClose && onClose();
  };

  const Categories = {
    DRINK: "drink",
    STEP: "step",
    PIZZA: "pizza",
    FLAVOR: "flavor",
  };

  return (
    <>
      {[Categories.DRINK].includes(product.type) && (
        <Drink
          isUpdate={cartItemIndex !== null}
          onSave={(e) => onSave(e)}
          product={product}
          onClose={onClose}
        />
      )}

      {[Categories.STEP].includes(product.type) && (
        <Step
          isUpdate={cartItemIndex !== null}
          onSave={(e) => onSave(e)}
          product={product}
          onClose={onClose}
        />
      )}

      {/* {[Categories.STEP].includes(product.type) && EcleticaCatalog.isCombination(product) && (
        <EcleticaStep
          isUpdate={cartItemIndex !== null}
          onSave={(e) => onSave(e)}
          product={product}
          onClose={onClose}
        />
      )} */}

      {[Categories.PIZZA, Categories.FLAVOR].includes(product.type) && (
        <Pizza
          isUpdate={cartItemIndex !== null}
          onSave={(e) => onSave(e)}
          product={product}
          onClose={onClose}
          sizes={product.sizes}
        />
      )}
    </>
  );
}
